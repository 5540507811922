/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate } from "react-router-dom";
import RangSelect from "../../components/rangSelect";
import DashboardMap from "../../components/dashboardMap/dashboardMap.js";
import DashboardChart from "../../components/dashboardChart/dashboardChart.js";
import React, { useState, useEffect, useRef } from "react";
import { getItem, getUserInfo } from "../../utils/storage.js";
import { sendGet, sendGetKeyValue } from "../../utils/httpUtils.js";
import { toastError, ToastSucces } from "../../utils/check.js";
import Select from "react-select";
import Loading from "../Loading/loading.js";
import _, { lte } from "lodash";
import moment from "moment/moment.js";
import HgLendersSelectControl from "../controls/select-control/hg-lenders-select-control.jsx";
import qs from "qs";

export function DownloadCenter({ searchParam, updateSearch }) {
  const source = parseInt(getItem("source"));
  const toastId = useRef(null);
  const [isLoading, setLoading] = useState(true);
  const innerSearchParamsRef = useRef(null);
  const [lenderTypes, setLenderTypes] = useState([
    // { value: "HG", label: "HG" },
  ]);
  const [lenderTypeValue, setLenderTypeValue] = useState({
    value: "User",
    label: "User",
  });

  // const lenderTypes = [
  //   { value: "User", label: "User" },
  //   { value: "Location", label: "Location" },
  //   { value: "HG", label: "HG" },
  // ];

  const [stackedBar, setStackedBar] = useState([
    // {
    //     "healthCareId": 0,
    //     "yyyy": 0,
    //     "mm": 0,
    //     "totalSubmittedCount": 0,
    //     "totalPreApprovedCount": 0,
    //     "totalLeadCount": 0,
    //     "preApprovedRate": 0,
    //     "leadRate": 0,
    //     "totalSubmittedAmount": 0,
    //     "totalPreApprovedAmount": 0,
    //     "totalLeadAmount": 0,
    //     "avgSubmittedAmount": 0,
    //     "avgPreApprovedAmount": 0,
    //     "avgLeadAmount": 0,
    //     "preApprovedAmtRate": 0,
    //     "leadAmtRate": 0
    // }
  ]);

  const [stackedBarParam, setStackedBarParam] = useState({
    // "userIdCollection": 0,
    // "siteIdCollection": 0,
    // "lenderIdCollection": 0,
    // "cityCollection": undefined,
    lenderKpiType: undefined,
    startDate: "2022-1-1",
    endDate: new Date().toLocaleDateString(),
    timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
  const [details, setDetails] = useState();
  const [label, setLabel] = useState([]);
  const [avgLeadAmount, setAvgLeadAmount] = useState([]);
  const [avgPreApprovedAmount, setAvgPreApprovedAmount] = useState([]);
  const [avgSubmittedAmount, setAvgSubmittedAmount] = useState([]);
  const [leadAmtRate, setLeadAmtRate] = useState([]);
  const [leadRate, setLeadRate] = useState([]);
  const [preApprovedAmtRate, setPreApprovedAmtRate] = useState([]);
  const [preApprovedRate, setPreApprovedRate] = useState([]);
  const [totalLeadAmount, setTotalLeadAmount] = useState([]);
  const [totalLeadCount, setTotalLeadCount] = useState([]);
  const [totalPreApprovedAmount, setTotalPreApprovedAmount] = useState([]);
  const [totalPreApprovedCount, setTotalPreApprovedCount] = useState([]);
  const [totalSubmittedAmount, setTotalSubmittedAmount] = useState([]);
  const [totalSubmittedCount, setTotalSubmittedCount] = useState([]);
  const userType = JSON.parse(getItem("role"));

  useEffect(() => {
    if (_.isEqual(innerSearchParamsRef.current, searchParam)) {
      return;
    }
    innerSearchParamsRef.current = _.cloneDeep(searchParam);
    // getSummary()
    let temp = [];
    setLabel(temp);
    setAvgLeadAmount(temp);
    setAvgPreApprovedAmount(temp);
    setAvgSubmittedAmount(temp);
    setLeadAmtRate(temp);
    setLeadRate(temp);
    setPreApprovedAmtRate(temp);
    setPreApprovedRate(temp);
    setTotalLeadAmount(temp);
    setTotalLeadCount(temp);
    setTotalPreApprovedAmount(temp);
    setTotalPreApprovedCount(temp);
    setTotalSubmittedAmount(temp);
    setTotalSubmittedCount(temp);
    setStackedBar(temp);
    getLocations();
    if (source === 100) {
      setLenderTypes([
        { value: "User", label: "User" },
        { value: "Location", label: "Location" },
        { value: "Lender", label: "Lender" },
      ]);
    } else {
      setLenderTypes([
        { value: "User", label: "User" },
        { value: "Location", label: "Location" },
        { value: "HG", label: "HG" },
      ]);
    }
  }, [searchParam]);

  const getLocations = async () => {
    var url = "";
    if (source === 100) {
      url = "/ClientDashboard/Hg/HgKpiTopLenders";
    } else if (source === 200 || source === 201) {
      url = "ClientDashboard/Lender/DentiRateLenderKpiTopHgs";
    }
    try {
      let tempParam = {};
      tempParam.startDate = searchParam.startDate;
      tempParam.endDate = searchParam.endDate;
      tempParam.timeZoneId = searchParam.timeZoneId;
      tempParam.lenderKpiType = searchParam.lenderKpiType;
      tempParam.hgKpiType = searchParam.hgKpiType;

      const filteredEntries = Object.entries(tempParam).filter(
        ([key, value]) => value !== undefined
      );
      const params = new URLSearchParams(filteredEntries);
      let userIdCollection = searchParam.userIdCollection;
      let siteIdCollection = searchParam.siteIdCollection;
      let lenderIdCollection = searchParam.lenderIdCollection;
      let cityCollection = searchParam.cityCollection;

      for (let i = 0; i < userIdCollection.length; i++) {
        params.append("userIdCollection", userIdCollection[i].value);
      }
      for (let i = 0; i < siteIdCollection.length; i++) {
        params.append("siteIdCollection", siteIdCollection[i].value);
      }
      for (let i = 0; i < lenderIdCollection.length; i++) {
        params.append("lenderIdCollection", lenderIdCollection[i].value);
      }
      for (let i = 0; i < cityCollection.length; i++) {
        params.append("cityCollection", cityCollection[i].value);
      }
      const res = await sendGetKeyValue(url, tempParam);
      if (res.status === 200) {
        let newStackedBar = [];
        if (source === 100) {
          newStackedBar = [...res.data.data.hgKpiTopLenderCollection];
        } else if (source === 200 || source === 201) {
          newStackedBar = [...res.data.data.lenderKpiTopLenderCollection];
        }
        setStackedBar(newStackedBar);
        let tempLabels2 = [];
        newStackedBar.forEach((item, index) => {
          tempLabels2 = [...tempLabels2, item.label];
          setLabel((prevLabels) => [...prevLabels, item.label]);

          setAvgLeadAmount((prevAmts) => [...prevAmts, item.avgLeadAmount]);
          setAvgPreApprovedAmount((prevAmts) => [
            ...prevAmts,
            item.avgPreApprovedAmount,
          ]);
          setAvgSubmittedAmount((prevAmts) => [
            ...prevAmts,
            item.avgSubmittedAmount,
          ]);

          setLeadAmtRate((prevRates) => [...prevRates, item.leadAmtRate]);
          setLeadRate((prevRates) => [...prevRates, item.leadRate]);
          setPreApprovedAmtRate((prevRates) => [
            ...prevRates,
            item.preApprovedAmtRate,
          ]);
          setPreApprovedRate((prevRates) => [
            ...prevRates,
            item.preApprovedRate,
          ]);

          setTotalLeadAmount((prevTotals) => [
            ...prevTotals,
            item.totalLeadAmount,
          ]);
          setTotalLeadCount((prevCounts) => [
            ...prevCounts,
            item.totalLeadCount,
          ]);
          setTotalPreApprovedAmount((prevTotals) => [
            ...prevTotals,
            item.totalPreApprovedAmount,
          ]);
          setTotalPreApprovedCount((prevCounts) => [
            ...prevCounts,
            item.totalPreApprovedCount,
          ]);
          setTotalSubmittedAmount((prevTotals) => [
            ...prevTotals,
            item.totalSubmittedAmount,
          ]);
          setTotalSubmittedCount((prevCounts) => [
            ...prevCounts,
            item.totalSubmittedCount,
          ]);
        });
        getSummary();
        setLoading(false);
      }
    } catch (error) {
      // ;
      // toastError(error.response.statusText, toastId)
      setLoading(false);
    } finally {
    }
  };

  const getSummary = async () => {
    let tempParam = {};
    tempParam.startDate = searchParam.startDate;
    tempParam.endDate = searchParam.endDate;
    tempParam.timeZoneId = searchParam.timeZoneId;
    tempParam.lenderKpiType = searchParam.lenderKpiType;
    tempParam.hgKpiType = searchParam.hgKpiType;
    const filteredEntries = Object.entries(tempParam).filter(
      ([key, value]) => value !== undefined
    );
    const params = new URLSearchParams(filteredEntries);
    let userIdCollection = searchParam.userIdCollection;
    let siteIdCollection = searchParam.siteIdCollection;
    let lenderIdCollection = searchParam.lenderIdCollection;
    let cityCollection = searchParam.cityCollection;

    for (let i = 0; i < userIdCollection.length; i++) {
      params.append("userIdCollection", userIdCollection[i].value);
    }
    for (let i = 0; i < siteIdCollection.length; i++) {
      params.append("siteIdCollection", siteIdCollection[i].value);
    }
    for (let i = 0; i < lenderIdCollection.length; i++) {
      params.append("lenderIdCollection", lenderIdCollection[i].value);
    }
    for (let i = 0; i < cityCollection.length; i++) {
      params.append("cityCollection", cityCollection[i].value);
    }
    var url = "";
    if (source === 100) {
      url = "/ClientDashboard/Hg/HgKpiSummary";
    } else if (source == 200 || source == 201) {
      url = "/ClientDashboard/Lender/DentiRateLenderKpiSummary";
    }
    try {
      const res = await sendGetKeyValue(url, tempParam);
      if (res.status === 200) {
        let item = {};
        if (source == 100) {
          if (
            res.data.data.hgKpiSummaryCollection &&
            res.data.data.hgKpiSummaryCollection.length > 0
          ) {
            item = res.data.data.hgKpiSummaryCollection[0];
          }
        } else if (source == 200) {
          if (
            res.data.data.lenderKpiSummaryCollection &&
            res.data.data.lenderKpiSummaryCollection.length > 0
          ) {
            item = res.data.data.lenderKpiSummaryCollection[0];
          }
        }
        setDetails(item);
        if (!(Object.keys(item).length === 0 && item.constructor === Object)) {
          setLabel((prevLabels) => [...prevLabels, "Total"]);

          setAvgLeadAmount((prevAmts) => [...prevAmts, item.avgLeadAmount]);
          setAvgPreApprovedAmount((prevAmts) => [
            ...prevAmts,
            item.avgPreApprovedAmount,
          ]);
          setAvgSubmittedAmount((prevAmts) => [
            ...prevAmts,
            item.avgSubmittedAmount,
          ]);

          setLeadAmtRate((prevRates) => [...prevRates, item.leadAmtRate]);
          setLeadRate((prevRates) => [...prevRates, item.leadRate]);
          setPreApprovedAmtRate((prevRates) => [
            ...prevRates,
            item.preApprovedAmtRate,
          ]);
          setPreApprovedRate((prevRates) => [
            ...prevRates,
            item.preApprovedRate,
          ]);

          setTotalLeadAmount((prevTotals) => [
            ...prevTotals,
            item.totalLeadAmount,
          ]);
          setTotalLeadCount((prevCounts) => [
            ...prevCounts,
            item.totalLeadCount,
          ]);
          setTotalPreApprovedAmount((prevTotals) => [
            ...prevTotals,
            item.totalPreApprovedAmount,
          ]);
          setTotalPreApprovedCount((prevCounts) => [
            ...prevCounts,
            item.totalPreApprovedCount,
          ]);
          setTotalSubmittedAmount((prevTotals) => [
            ...prevTotals,
            item.totalSubmittedAmount,
          ]);
          setTotalSubmittedCount((prevCounts) => [
            ...prevCounts,
            item.totalSubmittedCount,
          ]);
        }
      }
    } catch (error) {
      // toastError(error.response.statusText, toastId)
      setLoading(false);
    }
  };
  // setStackedBarParam({ ...stackedBarParam, lenderKpiType: e.value })
  const searchSummary = async (e) => {
    setLoading(true);
    let temp = [];
    setLabel(temp);
    setAvgLeadAmount(temp);
    setAvgPreApprovedAmount(temp);
    setAvgSubmittedAmount(temp);
    setLeadAmtRate(temp);
    setLeadRate(temp);
    setPreApprovedAmtRate(temp);
    setPreApprovedRate(temp);
    setTotalLeadAmount(temp);
    setTotalLeadCount(temp);
    setTotalPreApprovedAmount(temp);
    setTotalPreApprovedCount(temp);
    setTotalSubmittedAmount(temp);
    setTotalSubmittedCount(temp);
    setStackedBar(temp);
    let param = searchParam;
    // TODO: Fix for lender & hg
    param.lenderKpiType = e.value;
    param.hgKpiType = e.value;
    // setStackedBarParam(param);
    updateSearch(param);
    setTimeout(() => {
      getLocations();
    }, 500);
  };

  return (
    <>
      <Loading loading={isLoading} />
      <div className='kpi_box mt_6' style={{ borderColor: "#3EA065" }}>
        <div className='kpi_box_title my_2 my_4_sm'>Summary Report</div>
        <div className='row'>
          <div className='mb_2 col col_2_sm'>
            {/* <select className="form_control">
                        <option style={{ padding: '12px 0' }}>Lender</option>
                    </select> */}
            <Select
              // defaultValue={[hcgList[2], hcgList[3]]}
              // styles={{
              //     control: (baseStyles, state) => ({
              //       ...baseStyles,
              //       width: '150px',
              //     }),
              //   }}
              name='colors'
              options={lenderTypes}
              className='basic-multi-select'
              classNamePrefix='select'
              onChange={(e) => {
                setLenderTypeValue(e);
                searchSummary(e);
              }}
              value={lenderTypeValue}
              // onChange={e=>setLocationInfo({...locationInfo,storeName:e.target.value.trim()})}
            />
          </div>
        </div>

        <div
          className='d_flex_sm justify_between my_2_md'
          style={{ overflowX: "auto" }}
        >
          <table
            className='kpi_summary_table'
            cellSpacing={10}
            style={{ overflowX: "auto" }}
          >
            <thead>
              <tr>
                <th scope='col' style={{ textAlign: "left" }}>
                  KPI
                </th>
                {label.map((item, index) => (
                  <th scope='col' key={index} style={{}}>
                    {item}
                  </th>
                ))}
                {/* <th scope="col" style={{ width: "200px" }}>#1 HGC</th>
                                <th scope="col" style={{ width: "200px" }}>#2 HGC</th>
                                <th scope="col" style={{ width: "200px" }}>#3 HGC</th>
                                <th scope="col" style={{ width: "200px" }}>#4 HGC</th>
                                <th scope="col" style={{ width: "200px" }}>Other</th>
                                <th scope="col" style={{ width: "200px" }}>Total</th> */}
              </tr>
            </thead>
            <tbody>
              {/* ${details.avgLeadAmount ? new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(details.avgLeadAmount) : 0}
            </div>
            <div className="ht_64 t_center mt_5 kpi_ft">{details.leadAmtRate ? parseFloat(details.leadAmtRate * 100).toFixed(2) : '0.0'}%</div> */}
              <tr style={{ width: "200px", textAlign: "center" }}>
                <td style={{}}>
                  {(userType || "").toUpperCase() === "LENDER"
                    ? "Average Lead Amount"
                    : "Average Prospect Amount"}
                </td>
                {avgLeadAmount.map((item, index) => (
                  <td key={index} style={{}}>
                    $
                    {item
                      ? new Intl.NumberFormat("en-US", {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }).format(item)
                      : 0}
                  </td>
                ))}
                {/* <td style={{ }}>{stackedBar[0].totalSubmittedCount}</td>
                                <td>London Calling</td>
                                <td>ne you shouldn't've</td>
                                <td>	London Calling</td>
                                <td>	London Calling</td>
                                <td>	London Calling</td> */}
              </tr>

              <tr style={{ width: "200px", textAlign: "center" }}>
                <td style={{}}>Average Pre-Approved Amount</td>
                {avgPreApprovedAmount.map((item, index) => (
                  <td key={index} style={{}}>
                    $
                    {item
                      ? new Intl.NumberFormat("en-US", {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }).format(item)
                      : 0}
                  </td>
                ))}
                {/* <td>{stackedBar[0].preApprovedRate}</td>
                                <td>London Calling</td>
                                <td>London Calling</td> */}
              </tr>

              <tr style={{ width: "200px", textAlign: "center" }}>
                <td style={{}}>Average Submitted Amount</td>
                {avgSubmittedAmount.map((item, index) => (
                  <td key={index} style={{}}>
                    $
                    {item
                      ? new Intl.NumberFormat("en-US", {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }).format(item)
                      : 0}
                  </td>
                ))}
                {/* <td>London Calling</td>
                                <td>London Calling</td>
                                <td>No More Heroes</td> */}
              </tr>
              <tr style={{ width: "200px", textAlign: "center" }}>
                <td style={{}}>
                  {(userType || "").toUpperCase() === "LENDER"
                    ? "Lead Amount Rate"
                    : "Prospect Amount Rate"}
                </td>
                {leadAmtRate.map((item, index) => (
                  <td key={index} style={{}}>
                    {item ? parseFloat(item * 100).toFixed(2) : "0.0"}%
                  </td>
                ))}
                {/* <td>London Calling</td>
                                <td>London Calling</td>
                                <td>No More Heroes</td> */}
              </tr>
              <tr style={{ width: "200px", textAlign: "center" }}>
                <td style={{}}>
                  {(userType || "").toUpperCase() === "LENDER"
                    ? "Lead Rate"
                    : "Prospect Rate"}
                </td>
                {leadRate.map((item, index) => (
                  <td key={index} style={{}}>
                    {item ? parseFloat(item * 100).toFixed(2) : "0.0"}%
                  </td>
                ))}
                {/* <td>London Calling</td>
                                <td>London Calling</td>
                                <td>No More Heroes</td> */}
              </tr>
              <tr style={{ width: "200px", textAlign: "center" }}>
                <td style={{}}>Pre-Approved Amount Rate</td>
                {preApprovedAmtRate.map((item, index) => (
                  <td key={index} style={{}}>
                    {item ? parseFloat(item * 100).toFixed(2) : "0.0"}%
                  </td>
                ))}
                {/* <td>London Calling</td>
                                <td>London Calling</td>
                                <td>No More Heroes</td> */}
              </tr>
              <tr style={{ width: "200px", textAlign: "center" }}>
                <td style={{}}>Pre-Approved Rate</td>
                {preApprovedRate.map((item, index) => (
                  <td key={index} style={{}}>
                    {item ? parseFloat(item * 100).toFixed(2) : "0.0"}%
                  </td>
                ))}
                {/* <td>London Calling</td>
                                <td>London Calling</td>
                                <td>No More Heroes</td> */}
              </tr>
              <tr style={{ width: "200px", textAlign: "center" }}>
                <td style={{}}>
                  {(userType || "").toUpperCase() === "LENDER"
                    ? "Lead Amount"
                    : "Prospect Amount"}
                </td>
                {totalLeadAmount.map((item, index) => (
                  <td key={index} style={{}}>
                    $
                    {item
                      ? new Intl.NumberFormat("en-US", {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }).format(item)
                      : 0}
                  </td>
                ))}
                {/* <td>London Calling</td>
                                <td>London Calling</td>
                                <td>No More Heroes</td> */}
              </tr>
              <tr style={{ width: "200px", textAlign: "center" }}>
                <td style={{}}>
                  {(userType || "").toUpperCase() === "LENDER"
                    ? "Leads"
                    : "Prospect Applications"}
                </td>
                {totalLeadCount.map((item, index) => (
                  <td key={index} style={{}}>
                    {item}
                  </td>
                ))}
                {/* <td>London Calling</td>
                                <td>London Calling</td>
                                <td>No More Heroes</td> */}
              </tr>
              <tr style={{ width: "200px", textAlign: "center" }}>
                <td style={{}}>Pre-Approved Amount</td>
                {totalPreApprovedAmount.map((item, index) => (
                  <td key={index} style={{}}>
                    $
                    {item
                      ? new Intl.NumberFormat("en-US", {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }).format(item)
                      : 0}
                  </td>
                ))}
                {/* <td>London Calling</td>
                                <td>London Calling</td>
                                <td>No More Heroes</td> */}
              </tr>

              <tr style={{ width: "200px", textAlign: "center" }}>
                <td style={{}}>Pre-Approved Applications</td>
                {totalPreApprovedCount.map((item, index) => (
                  <td key={index} style={{}}>
                    {item}
                  </td>
                ))}
              </tr>
              <tr style={{ width: "200px", textAlign: "center" }}>
                <td style={{}}>Submitted Amount</td>
                {totalSubmittedAmount.map((item, index) => (
                  <td key={index} style={{}}>
                    $
                    {item
                      ? new Intl.NumberFormat("en-US", {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }).format(item)
                      : 0}
                  </td>
                ))}
              </tr>
              <tr style={{ width: "200px", textAlign: "center" }}>
                <td style={{}}>Submitted Applications</td>
                {totalSubmittedCount.map((item, index) => (
                  <td key={index} style={{}}>
                    {item}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* <button className="btn btn-primary  col" style={{ backgroundColor: "#46B08B" }} >Download Center</button> */}
    </>
  );
}

export function KPIDefinitions({ searchParam }) {
  const source = parseInt(getItem("source"));
  // const loginUser = getUserInfo()
  const [hcgList, setHCGList] = useState([]);
  const [userList, setUserList] = useState([]);
  // const userHgSiteCollection = JSON.parse(getItem("locations"));
  // const [locations, setLoations] = useState(userHgSiteCollection);
  const [locations, setLoations] = useState();
  const [activeLocations, setActiveLoations] = useState([]);
  const [reportList, setReportList] = useState([]);
  const toastId = useRef(null);

  const [stackedBarParam, setStackedBarParam] = useState({
    // "userIdCollection": 0,
    // "siteIdCollection": 0,
    // "lenderIdCollection": 0,
    // "cityCollection": undefined,
    lenderKpiType: undefined,
    startDate: "2022-1-1",
    endDate: new Date().toLocaleDateString(),
    timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
  const [report, setReport] = useState({
    reportName: "",
    KPI: "",
    userFullName: "",
    HCG: "",
    lenderId: null,
    lenderName: null,
    Location: "",
    startDate: moment(new Date(new Date().setDate(1))).format("yyyy-MM-DD"),
    endDate: moment().format("yyyy-MM-DD"),
  });
  const [hgValue, setHgValue] = useState(null);
  const reportTypes = [
    { value: "Aggregated", label: "Aggregated" },
    { value: "Details", label: "Details" },
  ];
  useEffect(() => {
    // get user list

    getUserList();
    // getHCGList();
  }, []);
  useEffect(() => {
    // get user list

    setReportList(reportList);
  }, [reportList]);
  const getUserList = async () => {
    const url = "/Account/PaginatedUserList";
    try {
      const res = await sendGetKeyValue(url, {
        start: 0,
        records: 9999999,
        isActive: true,
      });
      // ;
      if (res.status === 200) {
        let userList = res.data.data.userList.map((user) => ({
          value: user.id,
          label: user.firstName + " " + user.lastName,
        }));
        let newItem = {
          label: "All Users",
          value: 0,
        };
        userList.unshift(newItem);

        setUserList(userList);
      }
    } catch (error) {
    } finally {
    }
    //filter user list
  };

  const createReport = () => {
    // let reports=[]
    // if(reportList.length>0){
    //  reports = reportList
    // }
    // reports.push(report)
    // // reportList
    // setReportList(reports)

    let reports = report;
    if (source == 100) {
      reports.KPI = "Aggregated";
    }
    if (reports.KPI == "" || reports.startDate == "" || reports.endDate == "") {
      toastError("Either Report Type or date is empty!", toastId);
      return false;
    }
    setReportList((prevList) => [...prevList, reports]);
  };
  const setReportHCG = (e) => {
    setHgValue(e || null);
    setReport({ ...report, HCG: e?.value || null });
  };
  const down = async (params) => {
    const downloadParams = {
      timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
      userIdCollection: params.userId ? [params.userId] : null,
      lenderIdCollection: params.lenderId ? [params.lenderId] : null,
      siteIdCollection: params.siteId ? [params.siteId] : null,
      startDate: params.startDate,
      endDate: params.endDate,
    };
    const query = qs.stringify(
      Object.fromEntries(
        Object.entries(downloadParams).filter(([_, v]) => !!v)
      ),
      { arrayFormat: "repeat" }
    );
    // debugger;
    var url = "";
    if (source === 100) {
      url = "ClientDashboard/Hg/DownloadHgKpiAggregated";
    } else if (source === 200 || source === 201) {
      if (params.KPI == "Aggregated") {
        url = "ClientDashboard/Lender/DownloadLenderKpiAggregated";
      } else {
        url = "ClientDashboard/Lender/DownloadLenderKpiDetails";
      }
    }

    try {
      let filename = "";
      fetch("/api/" + url + "?" + query)
        .then((response) => {
          const disposition = response.headers.get("Content-Disposition");
          if (disposition) {
            const regex = /filename\*?=['"]?(?:UTF-8['"]*)?([^;]*).*$/;
            const matches = regex.exec(disposition);
            if (matches && matches[1]) {
              filename = decodeURIComponent(matches[1].replace(/['"]/g, ""));
            } else {
              const simpleFilenameRegex = /filename=['"]?([^;]*)['"]?/;
              const simpleMatches = simpleFilenameRegex.exec(disposition);
              if (simpleMatches && simpleMatches[1]) {
                filename = simpleMatches[1].replace(/['"]/g, "");
              }
            }
          }

          return response.blob();
        })
        .then((blob) => {
          const blobUrl = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = blobUrl;
          link.download = filename || "default_filename.csv";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setTimeout(() => {
            URL.revokeObjectURL(blobUrl);
          }, 0);
        })
        .catch((error) =>
          console.error(
            "There has been a problem with your fetch operation:",
            error
          )
        );
      // window.open("https://dev.dentiratenetwork.com/api/ClientDashboard/Hg/DownloadHgKpiDetails?startDate=2022-1-1&endDate=2023-12-30&timeZoneId=Central%20Standard%20Time&api-version=1.0")
      // https://dev.dentiratenetwork.com/api/ClientDashboard/Hg/DownloadHgKpiDetails?startDate=2022-1-1&endDate=2023-12-30&timeZoneId=Central%20Standard%20Time&api-version=1.0
    } catch (error) {
      // toastError(error.response.statusText, toastId)
    }
  };
  const reportLists = () => {
    // TODO
    return reportList.map((report, i) => {
      return (
        <div key={i} className='col col_2_sm mt_4 mt_0_sm'>
          <div className='ms_0 ms_5_sm'>
            <div
              className='card_title'
              style={{ border: "none", padding: "0" }}
            >
              <div className='fs_lg'>
                <span>{report.reportName}</span>
              </div>
              <button
                className='btn down ms_auto'
                onClick={() => down(report)}
              />
              {/* <button className="btn delete" /> */}
            </div>
            <div className='card_content d_flex_sm justify_between my_2_md'>
              <table className='table' style={{ lineHeight: "30px" }}>
                <tbody className=''>
                  <tr>
                    <td className='color_gray fs_sm'>KPI:</td>
                    <td className='color_gray fs_sm'>{report.KPI}</td>
                  </tr>
                  <tr>
                    <td className='color_gray fs_sm'>User:</td>
                    <td className='color_gray fs_sm'>
                      {report.userFullName || "All"}
                    </td>
                  </tr>
                  {source >= 200 && (
                    <tr>
                      <td className='color_gray fs_sm'> HCG:</td>
                      <td className='color_gray fs_sm'>{report.HCG}</td>
                    </tr>
                  )}
                  {source >= 200 && (
                    <tr>
                      <td className='color_gray fs_sm'> Location:</td>
                      <td className='color_gray fs_sm'>
                        {report.Location || "All"}
                      </td>
                    </tr>
                  )}
                  {source < 200 && (
                    <tr>
                      <td className='color_gray fs_sm'> Lender:</td>
                      <td className='color_gray fs_sm'>
                        {report.lenderName || "All"}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td className='color_gray fs_sm'> From:</td>
                    <td className='color_gray fs_sm'>
                      {moment(report.startDate).format("MM/DD/yyyy")}
                    </td>
                  </tr>
                  <tr>
                    <td className='color_gray fs_sm'> To:</td>
                    <td className='color_gray fs_sm'>
                      {moment(report.endDate).format("MM/DD/yyyy")}
                    </td>
                  </tr>
                  <tr>
                    <td className='color_gray fs_sm'> Report Status:</td>
                    <td className='color_success fs_sm'>Ready</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      );
    });
  };
  return (
    <>
      <div className='kpi_box mt_6' style={{ borderColor: "#A03E4F" }}>
        <div className='kpi_box_title my_2 my_4_sm'>Download Center</div>
        <div className='row'>
          <div className='col col_2_sm'>
            <form className='form '>
              <div className='row'>
                <div className='mb_2 col col_2_sm col_1_md'>
                  <input
                    className='form_control'
                    type='text'
                    placeholder='Report Name'
                    onChange={(e) => {
                      setReport({
                        ...report,
                        reportName: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className='mb_2 col col_2_sm col_3_md d_none d_block_md'>
                  <input
                    className='form_control d_none d_block_md btn_priamry'
                    type='button'
                    value='Create'
                    style={{
                      backgroundColor: "var(--primary)",
                      color: "#fff",
                      fontSize: "15px",
                    }}
                    onClick={() => {
                      createReport();
                    }}
                  />
                </div>
              </div>
              {source !== 100 && (
                <div className='row'>
                  <div className='mb_2 col col_sm col_md'>
                    <Select
                      // defaultInputValue={reportTypes[0].value}
                      placeholder='Report Type'
                      name='colors'
                      options={reportTypes}
                      className='basic-multi-select'
                      classNamePrefix='select'
                      onChange={(e) => {
                        setReport({
                          ...report,
                          KPI: e.value,
                        });
                      }}
                      // onChange={(e) => { setStackedBarParam({ ...stackedBarParam, lenderKpiType: e.value }) }}
                    />
                  </div>
                </div>
              )}

              <div className='row'>
                <div className='mb_2 col col_sm col_md'>
                  <Select
                    clearable
                    placeholder='User'
                    name='colors'
                    options={userList}
                    className='basic-multi-select'
                    classNamePrefix='select'
                    onChange={(e) => {
                      setReport({
                        ...report,
                        userFullName: e?.label || null,
                        userId: e?.value || null,
                      });
                    }}
                    // onChange={(e) => { setStackedBarParam({ ...stackedBarParam, lenderKpiType: e.value }) }}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='mb_2 col col_sm col_md'>
                  {source === 100 && (
                    <HgLendersSelectControl
                      multi={false}
                      onChange={(e) => {
                        // TODO
                        setHgValue(e || null);
                        setReport({
                          ...report,
                          lenderId: e?.id || null,
                          lenderName: e?.name || null,
                        });
                      }}
                      className='basic-multi-select'
                      classNamePrefix='select'
                      clearable
                      value={hgValue}
                    />
                  )}
                </div>
              </div>
              {source != 100 && (
                <div className='row'>
                  <div className='mb_2 col col_sm col_md'>
                    <Select
                      // isMulti
                      placeholder='Location'
                      name='colors'
                      options={locations}
                      className='basic-multi-select'
                      classNamePrefix='select'
                      // onChange={(e) => { setActiveLoations(e) }}
                      onChange={(e) => {
                        setReport({
                          ...report,
                          Location: e.label,
                        });
                      }}
                    />
                  </div>
                </div>
              )}

              <div className='row'>
                <div className='mb_2 col_2'>
                  <input
                    type='Date'
                    value={report.startDate}
                    className='form_control'
                    onChange={(e) => {
                      setReport({
                        ...report,
                        startDate: e.target.value,
                      });
                    }}
                  ></input>
                </div>
                <div className='mb_2 col_2'>
                  <input
                    type='Date'
                    value={report.endDate}
                    className='form_control'
                    onChange={(e) => {
                      setReport({
                        ...report,
                        endDate: e.target.value,
                      });
                    }}
                  ></input>
                </div>
              </div>
              <div className='row'>
                <input
                  className='form_control d_none_md btn'
                  type='button'
                  value='Create'
                  style={{ backgroundColor: "var(--primary)", color: "#fff" }}
                  onClick={() => {
                    createReport();
                  }}
                />
              </div>
            </form>
          </div>
          {reportLists()}
        </div>
      </div>
      {/* <button className="btn btn-primary  col" style={{ backgroundColor: "#46B08B" }}>KPI Definitions</button> */}
    </>
  );
}

export function DashboardText() {
  const userType = JSON.parse(getItem("role"));

  return (
    <div className='my_6 fs_lg'>
      <div className='definitionsTitle'>
        <div className='fs_lg fw_bold mb_4 mt_2 mt_4_sm'>KPI Definitions</div>
      </div>
      <p style={{ marginBottom: "15px" }}>
        {" "}
        <em>Submitted Applications:</em> The total number of applications sent
        by DentiRate for pre-approval.
      </p>
      <p style={{ marginBottom: "15px" }}>
        {" "}
        <em>Pre-Approved Applications:</em> The total number of applications
        that have been pre-approved.
      </p>
      <p style={{ marginBottom: "15px" }}>
        {" "}
        {(userType || "").toUpperCase() === "LENDER" ? (
          <span>
            <em>Leads:</em> The total number of leads that are sent by
            DentiRate.
          </span>
        ) : (
          <span>
            <em>Prospect Applications:</em> The total number of applications
            forwarded to lenders.
          </span>
        )}
      </p>
      <p style={{ marginBottom: "15px" }}>
        {" "}
        <em>Pre-Approved Rate:</em> The percentage of applications pre-approved
        through the DentiRate app out of all submitted applications..
      </p>
      <p style={{ marginBottom: "15px" }}>
        {" "}
        {(userType || "").toUpperCase() === "LENDER" ? (
          <span>
            <em>Lead Rate:</em> The percentage of leads delivered from all
            applications requested for pre-approval through the DentiRate App.
          </span>
        ) : (
          <span>
            <em>Prospect Rate:</em> The percentage of prospect applications out
            of all applications requested for pre-approval through the DentiRate
            App.
          </span>
        )}
      </p>
      <p style={{ marginBottom: "15px" }}>
        {" "}
        <em>Submitted Amount:</em> The total requested amount via DentiRate App.
      </p>
      <p style={{ marginBottom: "15px" }}>
        {" "}
        <em>Pre-Approved Amount:</em> The total amount pre-approved by lender.
      </p>
      <p style={{ marginBottom: "15px" }}>
        {(userType || "").toUpperCase() === "LENDER" ? (
          <span>
            <em>Lead Amount:</em> The total amount of finalized leads.
          </span>
        ) : (
          <span>
            <em>Prospect Amount:</em> The total amount of prospect applications.
          </span>
        )}
      </p>
      <p style={{ marginBottom: "15px" }}>
        {" "}
        <em>Average Submitted Amount:</em> Total requested amount / Number of
        submitted applications.
      </p>
      <p style={{ marginBottom: "15px" }}>
        {" "}
        <em>Average Pre-Approved Amount:</em> Total pre-approved amount / Number
        of pre-approved applications.
      </p>
      <p style={{ marginBottom: "15px" }}>
        {" "}
        {(userType || "").toUpperCase() === "LENDER" ? (
          <span>
            <em>Average Lead Amount:</em> Total lead amount / Number of leads.
          </span>
        ) : (
          <span>
            <em>Average Prospect Amount:</em> Total Prospect amount / Number of
            prospect applications.
          </span>
        )}
      </p>
      <p style={{ marginBottom: "15px" }}>
        <em>Number of Active Users:</em> The number of users with active
        profile.
      </p>
      <p style={{ marginBottom: "15px" }}>
        {" "}
        <em>Number of Active Locations:</em> The number of registered locations
        that are active.
      </p>
      <p style={{ marginBottom: "15px" }}>
        {" "}
        <em>Number of Active HCG:</em> The number of registered HCG that are
        active.
      </p>
      <p style={{ marginBottom: "15px" }}>
        {" "}
        <em>Healthcare Group::</em> A healthcare organization with multiple
        locations. In the dental industry, we categorize a DSO as an HCG.
      </p>
    </div>
  );
}

export function SummaryReport() {
  const source = parseInt(getItem("source"));
  const toastId = useRef(null);

  const [map, setStackedBar] = useState([
    {
      healthCareId: 0,
      yyyy: 0,
      mm: 0,
      totalSubmittedCount: 0,
      totalPreApprovedCount: 0,
      totalLeadCount: 0,
      preApprovedRate: 0,
      leadRate: 0,
      totalSubmittedAmount: 0,
      totalPreApprovedAmount: 0,
      totalLeadAmount: 0,
      avgSubmittedAmount: 0,
      avgPreApprovedAmount: 0,
      avgLeadAmount: 0,
      preApprovedAmtRate: 0,
      leadAmtRate: 0,
    },
  ]);

  const [mapParam, setMapParam] = useState({
    // "userIdCollection": 0,
    // "siteIdCollection": 0,
    // "lenderIdCollection": 0,
    // "cityCollection": undefined,
    startDate: "2022-1-1",
    endDate: new Date().toLocaleDateString(),
    timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
  useEffect(() => {
    getLocations();
  }, []);
  const getLocations = async () => {
    var url = "";
    if (source === 100) {
      url = "/ClientDashboard/Hg/HgKpiStackedBar";
    } else if (source === 200 || source === 201) {
      url = "/ClientDashboard/Lender/DentiRateLenderKpiStackedBar";
    }
    try {
      const res = await sendGetKeyValue(url, mapParam);
      if (res.status === 200) {
        source === 100
          ? setStackedBar([res.data.data.hgKpiStackedBarCollection])
          : (source === 200 || source === 201) &&
            setStackedBar([res.data.data.lenderKpiStackedBarCollection]);
      }
    } catch (error) {
      // ;
      // toastError(error.response.statusText, toastId)
    }
  };
  return (
    <>
      <div className='kpi_box mt_4' style={{ borderColor: "#9747FF" }}>
        <div className='kpi_box_title my_2 my_4_sm'>KPI Reporting</div>
        <div className='row'>
          <div className='mb_2 col col_2_sm col_2_md'>
            <select className='form_control'>
              <option style={{ padding: "12px 0" }}>KPI</option>
            </select>
          </div>
        </div>
        <div className='row mt_3'>
          <DashboardMap />

          <DashboardChart />
        </div>
      </div>

      {/* <button className="btn btn-primary col" style={{ backgroundColor: "#46B08B" }} >Summary Report</button> */}
    </>
  );
}
export function Header() {
  const navigate = useNavigate();

  return (
    <>
      <header>
        <div className='nav container'>
          <button className='btn_return me_3' onClick={() => navigate("/")} />
          <div className='title'>Dashboard</div>
        </div>
      </header>
    </>
  );
}
