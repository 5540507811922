/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { commonContextActions, useCommonContext } from "../../common-context";
import { ToastContainer } from "react-toastify";
import { checkIsEmail, toastInfo, toastError } from "../../utils/check.js";
import { sendGet, sendPost, sendPostByJson } from "../../utils/httpUtils.js";
import {
  setItem,
  setUserInfo,
  setToken,
  clearAllStorages,
} from "../../utils/storage.js";
import logo from "../../assets/logo.svg";
import apiLoadingStatus from "../../infrastructure/constants/api/api-loading-status.js";
import { useTranslation } from "react-i18next";

export default function LoginSubject() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const toastId = useRef(null);
  const [loginInfo, setLoginInfo] = useState({ email: "", password: "" });

  const [error, setError] = useState({
    emailError: false,
    passwordError: false,
  });
  const [{ user, config }, dispatchCommonContext] = useCommonContext();
  useEffect(() => {
    logOut();
    clearAllStorages();
  }, []);
  const logOut = async () => {
    navigate("/account/login");

    await sendGet(`/Account/Logout`);
  };
  const signHandle = () => {
    if (!loginInfo.email) {
      setError({ emailError: true, passwordError: false });
    }
    if (!loginInfo.password) {
      setError({ emailError: false, passwordError: true });
    }
    if (!loginInfo.email && !loginInfo.password) {
      setError({ emailError: true, passwordError: true });
      return;
    }
    if (!checkIsEmail(loginInfo.email)) {
      setError({ emailError: true, passwordError: false });
      return false;
    }
    if (loginInfo.email.trim() && loginInfo.password.trim()) {
      setIsLoading(true);
      setError({ emailError: false, passwordError: false });
      loginHandle();
    }
  };
  const singLogin = (e) => {
    if (e.keyCode === 13 || e.key === "Enter") {
      if (!loginInfo.email.trim() && !loginInfo.password.trim()) {
        setError({ emailError: true, passwordError: true });
        return false;
      }
      if (!loginInfo.email.trim()) {
        setError({ emailError: true, passwordError: false });
        return false;
      }
      if (!loginInfo.password.trim()) {
        setError({ emailError: false, passwordError: true });
        return false;
      }
      if (loginInfo.email.trim() && loginInfo.password.trim()) {
        setIsLoading(true);
        setError({ emailError: false, passwordError: false });
        loginHandle();
      }
    }
  };
  const loginHandle = async () => {
    setError({
      emailError: false,

      passwordError: false,
    });

    const url = "Account/Login";
    try {
      const res = await sendPostByJson(url, {
        contactText: loginInfo.email,
        password: loginInfo.password,
      });
      if (res.status === 200) {
        setTimeout(() => {
          toastError("Please login again", toastId);
          logOut();
        }, 60 * 60 * 1000);

        // toastInfo('Load success', toastId)

        dispatchCommonContext({
          type: commonContextActions.updateUser,
          payload: {
            loadingStatus: apiLoadingStatus.loaded,
            data: { user: res.data?.data?.membershipUser || null },
          },
        });
        localStorage.setItem("isLogin", 1);

        // setUserInfo(res.data.data.membershipUser)
        // let simplifiedCollection = res.data.data.membershipUser.hgSiteCollection.map(site => {
        //   return { id: site.id, nickname: site.nickname };
        // });

        // setItem("locations", simplifiedCollection);
        setItem(
          "locations",
          res.data.data.membershipUser.hgSiteCollection
            ? res.data.data.membershipUser.hgSiteCollection
            : []
        );
        setItem("id", res.data.data.membershipUser.id);
        setItem("organizationId", res.data.data.membershipUser.organizationId);
        setItem("role", res.data.data.membershipUser.userType);
        setItem(
          "roleInOrganization",
          res.data.data.membershipUser.roleInOrganization
        );

        let group = res.data.data.membershipUser.userType;
        let roleInOrganization =
          res.data.data.membershipUser.roleInOrganization;
        if (group === "DentiRate") {
          setItem("source", 1);
          navigate("/");
        } else if (group === "HealthcareGroup") {
          if (
            roleInOrganization === "Administrator" ||
            roleInOrganization === "SuperAdministrator"
          ) {
            setItem("source", 100);
            navigate("/");
          } else {
            setItem("source", 101);
            navigate("/applications");
          }
        } else if (group === "Lender") {
          if (
            roleInOrganization === "Administrator" ||
            roleInOrganization === "SuperAdministrator"
          ) {
            setItem("source", 200);
          } else {
            setItem("source", 201);
          }
          navigate("/");
        } else if (group === "Regular") {
          setItem("source", 300);
          navigate("/patient-home");
        }
      } else {
        toastError("Invalid credentials", toastId);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error.response.status === 423) {
        toastError("The account has been locked", toastId);
      } else {
        toastError("Invalid credentials", toastId);
      }
    }
  };

  return (
    <>
      <div className='mb_4' style={{ width: "252px" }}>
        <img src={logo} />
      </div>
      <form className='form mb_2'>
        <div className='mb_3'>
          {/* <label className='form_label' htmlFor='userName'>
            {t("email")}
            <br />
            {t("validation:email")}
          </label> */}

          <input
            id='userName'
            className='form_control'
            type='text'
            placeholder='Your email'
            value={loginInfo.email}
            onChange={(e) =>
              setLoginInfo({ ...loginInfo, email: e.target.value })
            }
            onKeyUp={(e) => {
              singLogin(e);
            }}
          />
          <div
            className='color_error mb_1'
            style={{ visibility: error.emailError ? "visible" : "hidden" }}
          >
            Please enter your correct email address!
          </div>
        </div>
        <div className=''>
          <label className='form_label' htmlFor=''>
            Password
          </label>
          <input
            className='form_control'
            type='password'
            placeholder='Your password'
            value={loginInfo.password}
            onChange={(e) =>
              setLoginInfo({ ...loginInfo, password: e.target.value })
            }
            onKeyUp={(e) => {
              singLogin(e);
            }}
          />
          <div
            className='color_error mb_1'
            style={{ visibility: error.passwordError ? "visible" : "hidden" }}
          >
            Please enter correct password!
          </div>
        </div>
      </form>
      <div className='service'>
        <span>By continuing, you agree to our </span>
        <a className='link_primary fw_bold' href='/terms-of-service'>
          Terms of Service
        </a>
      </div>
      <button
        className='btn btn-primary w_100 mt_3'
        onClick={signHandle}
        disabled={isLoading}
      >
        {isLoading ? "Loading..." : "Login"}
      </button>
      <a
        className='link_primary fw_middle mt_3'
        href=''
        onClick={() => navigate("/account/recovery")}
      >
        Forgot Password?
      </a>
    </>
  );
}
