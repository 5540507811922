import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./styles.module.scss";
import { useCommonContext } from "../../../common-context";
import { TitleBarExtended } from "../../../components/header";
import ConsentText from "../../../components/PrivacyAgreementModule/consent-text";
import { Alert, Col, Row } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import ConsumerApplicationInputs from "./application-inputs";
import _, { set } from "lodash";
import { getObjectPropertyNames } from "../../../infrastructure/helpers";
import parsePhoneNumber from "libphonenumber-js";
import { regexPatterns } from "../../../infrastructure/constants";
import {
  getDefaultApplication,
  getDefaultApplicationValidations,
} from "./utils/utils";
import { sendGetKeyValue, sendPostByJson } from "../../../utils/httpUtils";
import { toastError, toastSuccess } from "../../../utils/check";
import { toast } from "react-toastify";
import { apiLoadingStatus } from "../../../infrastructure/constants/api";
import Loading from "../../../components/Loading/loading";
import TitleBreadcrumbs from "../../../components/TitleBreadcrumbs";
import TitleBar from "../../../components/header/titleBar.js"; // Import TitleBar
import Footer from '../../../components/Footer/footer.js';

const steps = {
  initial: 0,
  consent: 1,
  loanInformation: 2,
  submitted: 3,
  error: 4,
};

const ApplicationForm = ({ initialApplication = undefined }) => {
  const navigate = useNavigate();
  let { applicationId } = useParams();

  const [{ user }] = useCommonContext();
  /// steps
  /// consent
  /// 1: loan information
  /// 2: applicant information
  const [step, setStep] = useState(steps.initial);
  const [loadingStatus, setLoadingStatus] = useState(apiLoadingStatus.unloaded);
  const [message, setMessage] = useState({
    text: null,
    messageType: "warning",
  });
  const [application, setApplication] = useState(
    initialApplication || getDefaultApplication()
  );
  const [applicationValidations, setApplicationValidations] = useState(
    getDefaultApplicationValidations()
  );
  const isExistingApplication = useMemo(
    () => !!application?.id && !!application?.updateKey,
    [application]
  );

  const getApplicationById = async (id) => {
    const url = "/Application/GetSimplifiedVersionById";
    const data = {
      id,
    };
    setLoadingStatus(apiLoadingStatus.loading);
    sendGetKeyValue(url, data)
      .then((res) => {
        if (!res?.status) {
          return Promise.reject("An error occured");
        }
        switch (res.status) {
          case 200:
            let consumerApplication = res.data.data.consumerApplication;
            let info = res.data.data.consumerApplication.applicantInfo;

            setApplication({
              ...application,
              id,
              treatmentType: consumerApplication.treatmentType,
              amount: consumerApplication.amount,
              hg_SiteId: consumerApplication.locationId,
              updateKey: consumerApplication.updateKey,
              applicationStatus: consumerApplication.applicationStatus,
              applicantInfo: {
                ...info,
                ssn: undefined,
              },
            });
            setStep(steps.loanInformation);
            break;
          case 401:
          case 403:
            navigate("/account/login");
            return;
          case 404:
            setMessage({
              messageType: "warning",
              text: "404: No application was found.",
            });
            setStep(steps.error);
            break;
          default:
            return Promise.reject("An error occured");
        }
      })
      .catch(() => {
        setMessage({
          messageType: "warning",
          text: "500: An error occured.",
        });
        setStep(steps.error);
      })
      .finally(() => {
        setLoadingStatus(apiLoadingStatus.unloaded);
      });
  };

  const handleConsent = (value) => {
    if (!value) {
      navigate("/applications");
      return;
    }
    setApplication({
      ...application,
      applicantInfo: {
        ...application.applicantInfo,
        initialConsentTimestamp: moment().utc().format(),
      },
    });
    setStep(steps.loanInformation);
  };

  const validate = (name, value, obj) => {
    const result = validateProperty(name, value, obj || application);
    const temp = { ...applicationValidations };
    _.set(temp, name, result);
    setApplicationValidations(temp);
  };

  const validateProperty = (name, value, obj) => {
    switch (name) {
      case "treatmentType":
      case "applicantInfo.lastName":
      case "applicantInfo.firstName":
      case "applicantInfo.preferredLanguage":
      case "applicantInfo.street1":
      case "applicantInfo.state":
      case "applicantInfo.city":
        return !!value && typeof value === "string" && value.length > 0;
      case "amount":
      case "temp_applicantInfo.annualIncome":
        return typeof value === "number" && !!value && value > 0;
      case "temp_applicantInfo.preferredMonthlyPayment":
        return (
          typeof value === "number" &&
          !!value &&
          value > 0 &&
          (!obj?.amount || value < obj.amount)
        );
      case "hg_SiteId":
        return !!value && value > 0;
      case "applicantInfo.ssn":
        return (
          !!obj.applicantInfo.noSsn ||
          (!!value &&
            typeof value === "string" &&
            value.length > 0 &&
            !!value.trim().match(/^\d{9}$/gi))
        );
      case "applicantInfo.initialConsentTimestamp":
        return !!value && moment(value) && moment(value).isValid();
      case "applicantInfo.doB":
        return (
          !!value &&
          moment(value) &&
          moment(value).isValid() &&
          moment(value).isAfter(moment().add(-100, "y")) &&
          moment(value).isSameOrBefore(moment().add(-18, "y"))
        );

      case "applicantInfo.zipcode":
        return (
          !!value &&
          typeof value === "string" &&
          value.length > 0 &&
          !!value.trim().match(/^\d{5}$/)
        );

      case "applicantInfo.emailAddress":
        return (
          !!value &&
          typeof value === "string" &&
          value.length > 0 &&
          !!regexPatterns.email.test(value)
        );
      case "applicantInfo.hasAvaiableCreditLine":
        return value != null;
      case "applicantInfo.cellPhone": {
        const phoneNumber = parsePhoneNumber(value || "", "US");
        return (
          !!value &&
          typeof value === "string" &&
          value.length > 0 &&
          !!phoneNumber &&
          phoneNumber.isPossible() &&
          phoneNumber.isValid()
        );
      }
      case "applicantInfo.homePhone":
      case "applicantInfo.workPhone": {
        const phoneNumber = parsePhoneNumber(value || "", "US");
        return (
          !value ||
          (typeof value === "string" &&
            (value.length === 0 ||
              (!!phoneNumber &&
                phoneNumber.isPossible() &&
                phoneNumber.isValid())))
        );
      }
      case "applicantInfo.emailAddress":
        return !!value && typeof value === "string" && value;
      case "applicantInfo.identificationProvided":
      case "applicantInfo.identificationType":
      default:
        return true;
    }
  };
  const validateAll = () => {
    const propertyNames = getObjectPropertyNames({ ...application });
    let isValid = true;
    const temp = { ...applicationValidations };

    for (let i = 0; i < propertyNames.length; i += 1) {
      const propertyName = propertyNames[i];
      if (!propertyName) {
        continue;
      }
      const v = _.get(application, propertyName);
      const result = validateProperty(
        propertyName,
        _.get(application, propertyName),
        application
      );
      _.set(temp, propertyName, result);
      isValid = isValid && result;
    }
    setApplicationValidations(temp);
    return isValid;
  };

  const submitApplication = async (submitApplication) => {
    if (!validateAll() || loadingStatus === apiLoadingStatus.loading) {
      return;
    }
    setLoadingStatus(apiLoadingStatus.loading);
    const url = !isExistingApplication
      ? "/Application/AddConsumerApplication"
      : "/Application/UpdateApplication";
    const data = {
      ...application,
      submitApplication: !!submitApplication,
    };
    sendPostByJson(url, data)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          toastSuccess(
            <>Please ask applicant to check their device.</>,
            "application-form"
          );
          // setTimeout(() => {
          navigate("/applications");
          // }, 1000);
        } else {
          return Promise.reject(`API Response: ${res.status}`);
        }
      })
      .catch(() => {
        toastError(<>There is an error.</>, "application-form");
        setLoadingStatus(apiLoadingStatus.unloaded);
      });
  };

  const buttonDisabled = useMemo(
    () => loadingStatus === apiLoadingStatus.loading,
    [loadingStatus]
  );

  useEffect(() => {
    if (
      !!applicationId &&
      typeof applicationId === "string" &&
      applicationId.length > 0
    ) {
      getApplicationById(applicationId);
    } else {
      setStep(steps.consent);
    }
  }, [applicationId]);

  console.log(application);

  return (
    <div className='layout'>
      <TitleBar title={"New Application"} /> 
      <div className={"container"}>
        <div className='filter_box mt_2'>
          <Loading loading={buttonDisabled} />
          <TitleBreadcrumbs menu={"NEW APPLICATION"} />
        </div>
        {step === steps.error && !!message.text && (
          <>
          <Alert variant={message.messageType}>{message.text}</Alert>
          </>
        )}
        {step === steps.consent && (
          <div className={styles["step-container"]}>
            <ConsentText />
            <div className={`noprint ${styles["btn-container"]}`}>
              <Button
                variant='secondary'
                className={styles["btn-consent"]}
                onClick={() => handleConsent(false)}
              >
                Disagree
              </Button>
              <Button
                variant='primary'
                onClick={() => handleConsent(true)}
                className={styles["btn-consent"]}
              >
                Agree
              </Button>
            </div>
          </div>
        )}
        {step === steps.loanInformation && (
          <div className={styles["step-container"]}>
            <h1 className={styles["step-title"]}>CONSUMER APPLICATION FORM</h1>
            <ConsumerApplicationInputs
              user={user}
              application={application}
              onChange={(temp) => {
                setApplication(temp);
              }}
              applicationValidations={applicationValidations}
              validateInputs={validate}
              isExistingApplication={isExistingApplication}
            />
            <div className={`noprint ${styles["btn-container"]}`}>
              <Button
                disabled={buttonDisabled}
                variant='secondary'
                className={styles["btn-consent"]}
                onClick={() =>
                  (!isExistingApplication && setStep(steps.consent)) ||
                  navigate("/applications")
                }
              >
                Back
              </Button>

              <Button
                disabled={buttonDisabled}
                variant='dark'
                onClick={() => submitApplication(false)}
                className={styles["btn-consent"]}
              >
                Save
              </Button>
              <Button
                disabled={buttonDisabled}
                variant='primary'
                onClick={() => submitApplication(true)}
                className={styles["btn-consent"]}
              >
                Submit
              </Button>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default ApplicationForm;
